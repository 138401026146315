"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PythConnection = void 0;
var index_1 = require("./index");
var ONES = '11111111111111111111111111111111';
/**
 * Reads Pyth price data from a solana web3 connection. This class uses a callback-driven model,
 * similar to the solana web3 methods for tracking updates to accounts.
 */
var PythConnection = /** @class */ (function () {
    /** Create a PythConnection that reads its data from an underlying solana web3 connection.
     *  pythProgramKey is the public key of the Pyth program running on the chosen solana cluster.
     */
    function PythConnection(connection, pythProgramKey, commitment) {
        if (commitment === void 0) { commitment = 'finalized'; }
        this.productAccountKeyToProduct = {};
        this.priceAccountKeyToProductAccountKey = {};
        this.callbacks = [];
        this.connection = connection;
        this.pythProgramKey = pythProgramKey;
        this.commitment = commitment;
    }
    PythConnection.prototype.handleProductAccount = function (key, account) {
        var _a = index_1.parseProductData(account.data), priceAccountKey = _a.priceAccountKey, type = _a.type, product = _a.product;
        this.productAccountKeyToProduct[key.toString()] = product;
        if (priceAccountKey.toString() !== ONES) {
            this.priceAccountKeyToProductAccountKey[priceAccountKey.toString()] = key.toString();
        }
    };
    PythConnection.prototype.handlePriceAccount = function (key, account) {
        var product = this.productAccountKeyToProduct[this.priceAccountKeyToProductAccountKey[key.toString()]];
        if (product === undefined) {
            // This shouldn't happen since we're subscribed to all of the program's accounts,
            // but let's be good defensive programmers.
            throw new Error('Got a price update for an unknown product. This is a bug in the library, please report it to the developers.');
        }
        var priceData = index_1.parsePriceData(account.data);
        for (var _i = 0, _a = this.callbacks; _i < _a.length; _i++) {
            var callback = _a[_i];
            callback(product, priceData);
        }
    };
    PythConnection.prototype.handleAccount = function (key, account, productOnly) {
        var base = index_1.parseBaseData(account.data);
        // The pyth program owns accounts that don't contain pyth data, which we can safely ignore.
        if (base) {
            switch (index_1.AccountType[base.type]) {
                case 'Mapping':
                    // We can skip these because we're going to get every account owned by this program anyway.
                    break;
                case 'Product':
                    this.handleProductAccount(key, account);
                    break;
                case 'Price':
                    if (!productOnly) {
                        this.handlePriceAccount(key, account);
                    }
                    break;
                case 'Test':
                    break;
                default:
                    throw new Error("Unknown account type: " + base.type + ". Try upgrading pyth-client.");
            }
        }
    };
    /** Start receiving price updates. Once this method is called, any registered callbacks will be invoked
     *  each time a Pyth price account is updated.
     */
    PythConnection.prototype.start = function () {
        return __awaiter(this, void 0, void 0, function () {
            var accounts, _i, accounts_1, account;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.connection.getProgramAccounts(this.pythProgramKey, this.commitment)];
                    case 1:
                        accounts = _a.sent();
                        for (_i = 0, accounts_1 = accounts; _i < accounts_1.length; _i++) {
                            account = accounts_1[_i];
                            this.handleAccount(account.pubkey, account.account, true);
                        }
                        this.connection.onProgramAccountChange(this.pythProgramKey, function (keyedAccountInfo, context) {
                            _this.handleAccount(keyedAccountInfo.accountId, keyedAccountInfo.accountInfo, false);
                        }, this.commitment);
                        return [2 /*return*/];
                }
            });
        });
    };
    /** Register callback to receive price updates. */
    PythConnection.prototype.onPriceChange = function (callback) {
        this.callbacks.push(callback);
    };
    /** Stop receiving price updates. Note that this also currently deletes all registered callbacks. */
    PythConnection.prototype.stop = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // There's no way to actually turn off the solana web3 subscription x_x, but there should be.
                // Leave this method in so we don't have to update our API when solana fixes theirs.
                // In the interim, delete callbacks.
                this.callbacks = [];
                return [2 /*return*/];
            });
        });
    };
    return PythConnection;
}());
exports.PythConnection = PythConnection;
