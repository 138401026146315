"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPythProgramKeyForCluster = void 0;
var web3_js_1 = require("@solana/web3.js");
/** Mapping from solana clusters to the public key of the pyth program. */
var clusterToPythProgramKey = {
    'mainnet-beta': 'FsJ3A3u2vn5cTVofAjvy6y5kwABJAqYWpe4975bi2epH',
    devnet: 'gSbePebfvPy7tRqimPoVecS2UsBvYv46ynrzWocc92s',
    testnet: '8tfDNiaEyrV6Q1U4DEXrEigs9DoDtkugzFbybENEbCDz',
};
/** Gets the public key of the Pyth program running on the given cluster. */
function getPythProgramKeyForCluster(cluster) {
    if (clusterToPythProgramKey[cluster] !== undefined) {
        return new web3_js_1.PublicKey(clusterToPythProgramKey[cluster]);
    }
    else {
        throw new Error("Invalid Solana cluster name: " + cluster + ". Valid options are: " + JSON.stringify(Object.keys(clusterToPythProgramKey)));
    }
}
exports.getPythProgramKeyForCluster = getPythProgramKeyForCluster;
