var HumanizeDurationLanguage = /** @class */ (function () {
    function HumanizeDurationLanguage() {
        var _this = this;
        this.languages = {
            ar: {
                y: function (c) {
                    return c === 1 ? "سنة" : "سنوات";
                },
                mo: function (c) {
                    return c === 1 ? "شهر" : "أشهر";
                },
                w: function (c) {
                    return c === 1 ? "أسبوع" : "أسابيع";
                },
                d: function (c) {
                    return c === 1 ? "يوم" : "أيام";
                },
                h: function (c) {
                    return c === 1 ? "ساعة" : "ساعات";
                },
                m: function (c) {
                    return ["دقيقة", "دقائق"][_this.getArabicForm(c)];
                },
                s: function (c) {
                    return c === 1 ? "ثانية" : "ثواني";
                },
                ms: function (c) {
                    return c === 1 ? "جزء من الثانية" : "أجزاء من الثانية";
                },
                decimal: ","
            },
            bg: {
                y: function (c) {
                    return ["години", "година", "години"][_this.getSlavicForm(c)];
                },
                mo: function (c) {
                    return ["месеца", "месец", "месеца"][_this.getSlavicForm(c)];
                },
                w: function (c) {
                    return ["седмици", "седмица", "седмици"][_this.getSlavicForm(c)];
                },
                d: function (c) {
                    return ["дни", "ден", "дни"][_this.getSlavicForm(c)];
                },
                h: function (c) {
                    return ["часа", "час", "часа"][_this.getSlavicForm(c)];
                },
                m: function (c) {
                    return ["минути", "минута", "минути"][_this.getSlavicForm(c)];
                },
                s: function (c) {
                    return ["секунди", "секунда", "секунди"][_this.getSlavicForm(c)];
                },
                ms: function (c) {
                    return ["милисекунди", "милисекунда", "милисекунди"][_this.getSlavicForm(c)];
                },
                decimal: ","
            },
            ca: {
                y: function (c) {
                    return "any" + (c === 1 ? "" : "s");
                },
                mo: function (c) {
                    return "mes" + (c === 1 ? "" : "os");
                },
                w: function (c) {
                    return "setman" + (c === 1 ? "a" : "es");
                },
                d: function (c) {
                    return "di" + (c === 1 ? "a" : "es");
                },
                h: function (c) {
                    return "hor" + (c === 1 ? "a" : "es");
                },
                m: function (c) {
                    return "minut" + (c === 1 ? "" : "s");
                },
                s: function (c) {
                    return "segon" + (c === 1 ? "" : "s");
                },
                ms: function (c) {
                    return "milisegon" + (c === 1 ? "" : "s");
                },
                decimal: ","
            },
            cs: {
                y: function (c) {
                    return ["rok", "roku", "roky", "let"][_this.getCzechOrSlovakForm(c)];
                },
                mo: function (c) {
                    return ["měsíc", "měsíce", "měsíce", "měsíců"][_this.getCzechOrSlovakForm(c)];
                },
                w: function (c) {
                    return ["týden", "týdne", "týdny", "týdnů"][_this.getCzechOrSlovakForm(c)];
                },
                d: function (c) {
                    return ["den", "dne", "dny", "dní"][_this.getCzechOrSlovakForm(c)];
                },
                h: function (c) {
                    return ["hodina", "hodiny", "hodiny", "hodin"][_this.getCzechOrSlovakForm(c)];
                },
                m: function (c) {
                    return ["minuta", "minuty", "minuty", "minut"][_this.getCzechOrSlovakForm(c)];
                },
                s: function (c) {
                    return ["sekunda", "sekundy", "sekundy", "sekund"][_this.getCzechOrSlovakForm(c)];
                },
                ms: function (c) {
                    return ["milisekunda", "milisekundy", "milisekundy", "milisekund"][_this.getCzechOrSlovakForm(c)];
                },
                decimal: ","
            },
            da: {
                y: function () {
                    return "år";
                },
                mo: function (c) {
                    return "måned" + (c === 1 ? "" : "er");
                },
                w: function (c) {
                    return "uge" + (c === 1 ? "" : "r");
                },
                d: function (c) {
                    return "dag" + (c === 1 ? "" : "e");
                },
                h: function (c) {
                    return "time" + (c === 1 ? "" : "r");
                },
                m: function (c) {
                    return "minut" + (c === 1 ? "" : "ter");
                },
                s: function (c) {
                    return "sekund" + (c === 1 ? "" : "er");
                },
                ms: function (c) {
                    return "millisekund" + (c === 1 ? "" : "er");
                },
                decimal: ","
            },
            de: {
                y: function (c) {
                    return "Jahr" + (c === 1 ? "" : "e");
                },
                mo: function (c) {
                    return "Monat" + (c === 1 ? "" : "e");
                },
                w: function (c) {
                    return "Woche" + (c === 1 ? "" : "n");
                },
                d: function (c) {
                    return "Tag" + (c === 1 ? "" : "e");
                },
                h: function (c) {
                    return "Stunde" + (c === 1 ? "" : "n");
                },
                m: function (c) {
                    return "Minute" + (c === 1 ? "" : "n");
                },
                s: function (c) {
                    return "Sekunde" + (c === 1 ? "" : "n");
                },
                ms: function (c) {
                    return "Millisekunde" + (c === 1 ? "" : "n");
                },
                decimal: ","
            },
            el: {
                y: function (c) {
                    return c === 1 ? "χρόνος" : "χρόνια";
                },
                mo: function (c) {
                    return c === 1 ? "μήνας" : "μήνες";
                },
                w: function (c) {
                    return c === 1 ? "εβδομάδα" : "εβδομάδες";
                },
                d: function (c) {
                    return c === 1 ? "μέρα" : "μέρες";
                },
                h: function (c) {
                    return c === 1 ? "ώρα" : "ώρες";
                },
                m: function (c) {
                    return c === 1 ? "λεπτό" : "λεπτά";
                },
                s: function (c) {
                    return c === 1 ? "δευτερόλεπτο" : "δευτερόλεπτα";
                },
                ms: function (c) {
                    return c === 1
                        ? "χιλιοστό του δευτερολέπτου"
                        : "χιλιοστά του δευτερολέπτου";
                },
                decimal: ","
            },
            en: {
                y: function (c) {
                    return "year" + (c === 1 ? "" : "s");
                },
                mo: function (c) {
                    return "month" + (c === 1 ? "" : "s");
                },
                w: function (c) {
                    return "week" + (c === 1 ? "" : "s");
                },
                d: function (c) {
                    return "day" + (c === 1 ? "" : "s");
                },
                h: function (c) {
                    return "hour" + (c === 1 ? "" : "s");
                },
                m: function (c) {
                    return "minute" + (c === 1 ? "" : "s");
                },
                s: function (c) {
                    return "second" + (c === 1 ? "" : "s");
                },
                ms: function (c) {
                    return "millisecond" + (c === 1 ? "" : "s");
                },
                decimal: "."
            },
            es: {
                y: function (c) {
                    return "año" + (c === 1 ? "" : "s");
                },
                mo: function (c) {
                    return "mes" + (c === 1 ? "" : "es");
                },
                w: function (c) {
                    return "semana" + (c === 1 ? "" : "s");
                },
                d: function (c) {
                    return "día" + (c === 1 ? "" : "s");
                },
                h: function (c) {
                    return "hora" + (c === 1 ? "" : "s");
                },
                m: function (c) {
                    return "minuto" + (c === 1 ? "" : "s");
                },
                s: function (c) {
                    return "segundo" + (c === 1 ? "" : "s");
                },
                ms: function (c) {
                    return "milisegundo" + (c === 1 ? "" : "s");
                },
                decimal: ","
            },
            et: {
                y: function (c) {
                    return "aasta" + (c === 1 ? "" : "t");
                },
                mo: function (c) {
                    return "kuu" + (c === 1 ? "" : "d");
                },
                w: function (c) {
                    return "nädal" + (c === 1 ? "" : "at");
                },
                d: function (c) {
                    return "päev" + (c === 1 ? "" : "a");
                },
                h: function (c) {
                    return "tund" + (c === 1 ? "" : "i");
                },
                m: function (c) {
                    return "minut" + (c === 1 ? "" : "it");
                },
                s: function (c) {
                    return "sekund" + (c === 1 ? "" : "it");
                },
                ms: function (c) {
                    return "millisekund" + (c === 1 ? "" : "it");
                },
                decimal: ","
            },
            fa: {
                y: function () {
                    return "سال";
                },
                mo: function () {
                    return "ماه";
                },
                w: function () {
                    return "هفته";
                },
                d: function () {
                    return "روز";
                },
                h: function () {
                    return "ساعت";
                },
                m: function () {
                    return "دقیقه";
                },
                s: function () {
                    return "ثانیه";
                },
                ms: function () {
                    return "میلی ثانیه";
                },
                decimal: "."
            },
            fi: {
                y: function (c) {
                    return c === 1 ? "vuosi" : "vuotta";
                },
                mo: function (c) {
                    return c === 1 ? "kuukausi" : "kuukautta";
                },
                w: function (c) {
                    return "viikko" + (c === 1 ? "" : "a");
                },
                d: function (c) {
                    return "päivä" + (c === 1 ? "" : "ä");
                },
                h: function (c) {
                    return "tunti" + (c === 1 ? "" : "a");
                },
                m: function (c) {
                    return "minuutti" + (c === 1 ? "" : "a");
                },
                s: function (c) {
                    return "sekunti" + (c === 1 ? "" : "a");
                },
                ms: function (c) {
                    return "millisekunti" + (c === 1 ? "" : "a");
                },
                decimal: ","
            },
            fo: {
                y: function () {
                    return "ár";
                },
                mo: function (c) {
                    return c === 1 ? "mánaður" : "mánaðir";
                },
                w: function (c) {
                    return c === 1 ? "vika" : "vikur";
                },
                d: function (c) {
                    return c === 1 ? "dagur" : "dagar";
                },
                h: function (c) {
                    return c === 1 ? "tími" : "tímar";
                },
                m: function (c) {
                    return c === 1 ? "minuttur" : "minuttir";
                },
                s: function () {
                    return "sekund";
                },
                ms: function () {
                    return "millisekund";
                },
                decimal: ","
            },
            fr: {
                y: function (c) {
                    return "an" + (c >= 2 ? "s" : "");
                },
                mo: function () {
                    return "mois";
                },
                w: function (c) {
                    return "semaine" + (c >= 2 ? "s" : "");
                },
                d: function (c) {
                    return "jour" + (c >= 2 ? "s" : "");
                },
                h: function (c) {
                    return "heure" + (c >= 2 ? "s" : "");
                },
                m: function (c) {
                    return "minute" + (c >= 2 ? "s" : "");
                },
                s: function (c) {
                    return "seconde" + (c >= 2 ? "s" : "");
                },
                ms: function (c) {
                    return "milliseconde" + (c >= 2 ? "s" : "");
                },
                decimal: ","
            },
            hr: {
                y: function (c) {
                    if (c % 10 === 2 || c % 10 === 3 || c % 10 === 4) {
                        return "godine";
                    }
                    return "godina";
                },
                mo: function (c) {
                    if (c === 1) {
                        return "mjesec";
                    }
                    else if (c === 2 || c === 3 || c === 4) {
                        return "mjeseca";
                    }
                    return "mjeseci";
                },
                w: function (c) {
                    if (c % 10 === 1 && c !== 11) {
                        return "tjedan";
                    }
                    return "tjedna";
                },
                d: function (c) {
                    return c === 1 ? "dan" : "dana";
                },
                h: function (c) {
                    if (c === 1) {
                        return "sat";
                    }
                    else if (c === 2 || c === 3 || c === 4) {
                        return "sata";
                    }
                    return "sati";
                },
                m: function (c) {
                    var mod10 = c % 10;
                    if ((mod10 === 2 || mod10 === 3 || mod10 === 4) && (c < 10 || c > 14)) {
                        return "minute";
                    }
                    return "minuta";
                },
                s: function (c) {
                    if (c === 10 ||
                        c === 11 ||
                        c === 12 ||
                        c === 13 ||
                        c === 14 ||
                        c === 16 ||
                        c === 17 ||
                        c === 18 ||
                        c === 19 ||
                        c % 10 === 5) {
                        return "sekundi";
                    }
                    else if (c % 10 === 1) {
                        return "sekunda";
                    }
                    else if (c % 10 === 2 || c % 10 === 3 || c % 10 === 4) {
                        return "sekunde";
                    }
                    return "sekundi";
                },
                ms: function (c) {
                    if (c === 1) {
                        return "milisekunda";
                    }
                    else if (c % 10 === 2 || c % 10 === 3 || c % 10 === 4) {
                        return "milisekunde";
                    }
                    return "milisekundi";
                },
                decimal: ","
            },
            hu: {
                y: function () {
                    return "év";
                },
                mo: function () {
                    return "hónap";
                },
                w: function () {
                    return "hét";
                },
                d: function () {
                    return "nap";
                },
                h: function () {
                    return "óra";
                },
                m: function () {
                    return "perc";
                },
                s: function () {
                    return "másodperc";
                },
                ms: function () {
                    return "ezredmásodperc";
                },
                decimal: ","
            },
            id: {
                y: function () {
                    return "tahun";
                },
                mo: function () {
                    return "bulan";
                },
                w: function () {
                    return "minggu";
                },
                d: function () {
                    return "hari";
                },
                h: function () {
                    return "jam";
                },
                m: function () {
                    return "menit";
                },
                s: function () {
                    return "detik";
                },
                ms: function () {
                    return "milidetik";
                },
                decimal: "."
            },
            is: {
                y: function () {
                    return "ár";
                },
                mo: function (c) {
                    return "mánuð" + (c === 1 ? "ur" : "ir");
                },
                w: function (c) {
                    return "vik" + (c === 1 ? "a" : "ur");
                },
                d: function (c) {
                    return "dag" + (c === 1 ? "ur" : "ar");
                },
                h: function (c) {
                    return "klukkutím" + (c === 1 ? "i" : "ar");
                },
                m: function (c) {
                    return "mínút" + (c === 1 ? "a" : "ur");
                },
                s: function (c) {
                    return "sekúnd" + (c === 1 ? "a" : "ur");
                },
                ms: function (c) {
                    return "millisekúnd" + (c === 1 ? "a" : "ur");
                },
                decimal: "."
            },
            it: {
                y: function (c) {
                    return "ann" + (c === 1 ? "o" : "i");
                },
                mo: function (c) {
                    return "mes" + (c === 1 ? "e" : "i");
                },
                w: function (c) {
                    return "settiman" + (c === 1 ? "a" : "e");
                },
                d: function (c) {
                    return "giorn" + (c === 1 ? "o" : "i");
                },
                h: function (c) {
                    return "or" + (c === 1 ? "a" : "e");
                },
                m: function (c) {
                    return "minut" + (c === 1 ? "o" : "i");
                },
                s: function (c) {
                    return "second" + (c === 1 ? "o" : "i");
                },
                ms: function (c) {
                    return "millisecond" + (c === 1 ? "o" : "i");
                },
                decimal: ","
            },
            ja: {
                y: function () {
                    return "年";
                },
                mo: function () {
                    return "月";
                },
                w: function () {
                    return "週";
                },
                d: function () {
                    return "日";
                },
                h: function () {
                    return "時間";
                },
                m: function () {
                    return "分";
                },
                s: function () {
                    return "秒";
                },
                ms: function () {
                    return "ミリ秒";
                },
                decimal: "."
            },
            ko: {
                y: function () {
                    return "년";
                },
                mo: function () {
                    return "개월";
                },
                w: function () {
                    return "주일";
                },
                d: function () {
                    return "일";
                },
                h: function () {
                    return "시간";
                },
                m: function () {
                    return "분";
                },
                s: function () {
                    return "초";
                },
                ms: function () {
                    return "밀리 초";
                },
                decimal: "."
            },
            lo: {
                y: function () {
                    return "ປີ";
                },
                mo: function () {
                    return "ເດືອນ";
                },
                w: function () {
                    return "ອາທິດ";
                },
                d: function () {
                    return "ມື້";
                },
                h: function () {
                    return "ຊົ່ວໂມງ";
                },
                m: function () {
                    return "ນາທີ";
                },
                s: function () {
                    return "ວິນາທີ";
                },
                ms: function () {
                    return "ມິນລິວິນາທີ";
                },
                decimal: ","
            },
            lt: {
                y: function (c) {
                    return c % 10 === 0 || (c % 100 >= 10 && c % 100 <= 20)
                        ? "metų"
                        : "metai";
                },
                mo: function (c) {
                    return ["mėnuo", "mėnesiai", "mėnesių"][_this.getLithuanianForm(c)];
                },
                w: function (c) {
                    return ["savaitė", "savaitės", "savaičių"][_this.getLithuanianForm(c)];
                },
                d: function (c) {
                    return ["diena", "dienos", "dienų"][_this.getLithuanianForm(c)];
                },
                h: function (c) {
                    return ["valanda", "valandos", "valandų"][_this.getLithuanianForm(c)];
                },
                m: function (c) {
                    return ["minutė", "minutės", "minučių"][_this.getLithuanianForm(c)];
                },
                s: function (c) {
                    return ["sekundė", "sekundės", "sekundžių"][_this.getLithuanianForm(c)];
                },
                ms: function (c) {
                    return ["milisekundė", "milisekundės", "milisekundžių"][_this.getLithuanianForm(c)];
                },
                decimal: ","
            },
            lv: {
                y: function (c) {
                    return ["gads", "gadi"][_this.getLatvianForm(c)];
                },
                mo: function (c) {
                    return ["mēnesis", "mēneši"][_this.getLatvianForm(c)];
                },
                w: function (c) {
                    return ["nedēļa", "nedēļas"][_this.getLatvianForm(c)];
                },
                d: function (c) {
                    return ["diena", "dienas"][_this.getLatvianForm(c)];
                },
                h: function (c) {
                    return ["stunda", "stundas"][_this.getLatvianForm(c)];
                },
                m: function (c) {
                    return ["minūte", "minūtes"][_this.getLatvianForm(c)];
                },
                s: function (c) {
                    return ["sekunde", "sekundes"][_this.getLatvianForm(c)];
                },
                ms: function (c) {
                    return ["milisekunde", "milisekundes"][_this.getLatvianForm(c)];
                },
                decimal: ","
            },
            ms: {
                y: function () {
                    return "tahun";
                },
                mo: function () {
                    return "bulan";
                },
                w: function () {
                    return "minggu";
                },
                d: function () {
                    return "hari";
                },
                h: function () {
                    return "jam";
                },
                m: function () {
                    return "minit";
                },
                s: function () {
                    return "saat";
                },
                ms: function () {
                    return "milisaat";
                },
                decimal: "."
            },
            nl: {
                y: function () {
                    return "jaar";
                },
                mo: function (c) {
                    return c === 1 ? "maand" : "maanden";
                },
                w: function (c) {
                    return c === 1 ? "week" : "weken";
                },
                d: function (c) {
                    return c === 1 ? "dag" : "dagen";
                },
                h: function () {
                    return "uur";
                },
                m: function (c) {
                    return c === 1 ? "minuut" : "minuten";
                },
                s: function (c) {
                    return c === 1 ? "seconde" : "seconden";
                },
                ms: function (c) {
                    return c === 1 ? "milliseconde" : "milliseconden";
                },
                decimal: ","
            },
            no: {
                y: function () {
                    return "år";
                },
                mo: function (c) {
                    return "måned" + (c === 1 ? "" : "er");
                },
                w: function (c) {
                    return "uke" + (c === 1 ? "" : "r");
                },
                d: function (c) {
                    return "dag" + (c === 1 ? "" : "er");
                },
                h: function (c) {
                    return "time" + (c === 1 ? "" : "r");
                },
                m: function (c) {
                    return "minutt" + (c === 1 ? "" : "er");
                },
                s: function (c) {
                    return "sekund" + (c === 1 ? "" : "er");
                },
                ms: function (c) {
                    return "millisekund" + (c === 1 ? "" : "er");
                },
                decimal: ","
            },
            pl: {
                y: function (c) {
                    return ["rok", "roku", "lata", "lat"][_this.getPolishForm(c)];
                },
                mo: function (c) {
                    return ["miesiąc", "miesiąca", "miesiące", "miesięcy"][_this.getPolishForm(c)];
                },
                w: function (c) {
                    return ["tydzień", "tygodnia", "tygodnie", "tygodni"][_this.getPolishForm(c)];
                },
                d: function (c) {
                    return ["dzień", "dnia", "dni", "dni"][_this.getPolishForm(c)];
                },
                h: function (c) {
                    return ["godzina", "godziny", "godziny", "godzin"][_this.getPolishForm(c)];
                },
                m: function (c) {
                    return ["minuta", "minuty", "minuty", "minut"][_this.getPolishForm(c)];
                },
                s: function (c) {
                    return ["sekunda", "sekundy", "sekundy", "sekund"][_this.getPolishForm(c)];
                },
                ms: function (c) {
                    return ["milisekunda", "milisekundy", "milisekundy", "milisekund"][_this.getPolishForm(c)];
                },
                decimal: ","
            },
            pt: {
                y: function (c) {
                    return "ano" + (c === 1 ? "" : "s");
                },
                mo: function (c) {
                    return c === 1 ? "mês" : "meses";
                },
                w: function (c) {
                    return "semana" + (c === 1 ? "" : "s");
                },
                d: function (c) {
                    return "dia" + (c === 1 ? "" : "s");
                },
                h: function (c) {
                    return "hora" + (c === 1 ? "" : "s");
                },
                m: function (c) {
                    return "minuto" + (c === 1 ? "" : "s");
                },
                s: function (c) {
                    return "segundo" + (c === 1 ? "" : "s");
                },
                ms: function (c) {
                    return "milissegundo" + (c === 1 ? "" : "s");
                },
                decimal: ","
            },
            ro: {
                y: function (c) {
                    return c === 1 ? "an" : "ani";
                },
                mo: function (c) {
                    return c === 1 ? "lună" : "luni";
                },
                w: function (c) {
                    return c === 1 ? "săptămână" : "săptămâni";
                },
                d: function (c) {
                    return c === 1 ? "zi" : "zile";
                },
                h: function (c) {
                    return c === 1 ? "oră" : "ore";
                },
                m: function (c) {
                    return c === 1 ? "minut" : "minute";
                },
                s: function (c) {
                    return c === 1 ? "secundă" : "secunde";
                },
                ms: function (c) {
                    return c === 1 ? "milisecundă" : "milisecunde";
                },
                decimal: ","
            },
            ru: {
                y: function (c) {
                    return ["лет", "год", "года"][_this.getSlavicForm(c)];
                },
                mo: function (c) {
                    return ["месяцев", "месяц", "месяца"][_this.getSlavicForm(c)];
                },
                w: function (c) {
                    return ["недель", "неделя", "недели"][_this.getSlavicForm(c)];
                },
                d: function (c) {
                    return ["дней", "день", "дня"][_this.getSlavicForm(c)];
                },
                h: function (c) {
                    return ["часов", "час", "часа"][_this.getSlavicForm(c)];
                },
                m: function (c) {
                    return ["минут", "минута", "минуты"][_this.getSlavicForm(c)];
                },
                s: function (c) {
                    return ["секунд", "секунда", "секунды"][_this.getSlavicForm(c)];
                },
                ms: function (c) {
                    return ["миллисекунд", "миллисекунда", "миллисекунды"][_this.getSlavicForm(c)];
                },
                decimal: ","
            },
            uk: {
                y: function (c) {
                    return ["років", "рік", "роки"][_this.getSlavicForm(c)];
                },
                mo: function (c) {
                    return ["місяців", "місяць", "місяці"][_this.getSlavicForm(c)];
                },
                w: function (c) {
                    return ["тижнів", "тиждень", "тижні"][_this.getSlavicForm(c)];
                },
                d: function (c) {
                    return ["днів", "день", "дні"][_this.getSlavicForm(c)];
                },
                h: function (c) {
                    return ["годин", "година", "години"][_this.getSlavicForm(c)];
                },
                m: function (c) {
                    return ["хвилин", "хвилина", "хвилини"][_this.getSlavicForm(c)];
                },
                s: function (c) {
                    return ["секунд", "секунда", "секунди"][_this.getSlavicForm(c)];
                },
                ms: function (c) {
                    return ["мілісекунд", "мілісекунда", "мілісекунди"][_this.getSlavicForm(c)];
                },
                decimal: ","
            },
            ur: {
                y: function () {
                    return "سال";
                },
                mo: function (c) {
                    return c === 1 ? "مہینہ" : "مہینے";
                },
                w: function (c) {
                    return c === 1 ? "ہفتہ" : "ہفتے";
                },
                d: function () {
                    return "دن";
                },
                h: function (c) {
                    return c === 1 ? "گھنٹہ" : "گھنٹے";
                },
                m: function () {
                    return "منٹ";
                },
                s: function () {
                    return "سیکنڈ";
                },
                ms: function () {
                    return "ملی سیکنڈ";
                },
                decimal: "."
            },
            sk: {
                y: function (c) {
                    return ["rok", "roky", "roky", "rokov"][_this.getCzechOrSlovakForm(c)];
                },
                mo: function (c) {
                    return ["mesiac", "mesiace", "mesiace", "mesiacov"][_this.getCzechOrSlovakForm(c)];
                },
                w: function (c) {
                    return ["týždeň", "týždne", "týždne", "týždňov"][_this.getCzechOrSlovakForm(c)];
                },
                d: function (c) {
                    return ["deň", "dni", "dni", "dní"][_this.getCzechOrSlovakForm(c)];
                },
                h: function (c) {
                    return ["hodina", "hodiny", "hodiny", "hodín"][_this.getCzechOrSlovakForm(c)];
                },
                m: function (c) {
                    return ["minúta", "minúty", "minúty", "minút"][_this.getCzechOrSlovakForm(c)];
                },
                s: function (c) {
                    return ["sekunda", "sekundy", "sekundy", "sekúnd"][_this.getCzechOrSlovakForm(c)];
                },
                ms: function (c) {
                    return ["milisekunda", "milisekundy", "milisekundy", "milisekúnd"][_this.getCzechOrSlovakForm(c)];
                },
                decimal: ","
            },
            sv: {
                y: function () {
                    return "år";
                },
                mo: function (c) {
                    return "månad" + (c === 1 ? "" : "er");
                },
                w: function (c) {
                    return "veck" + (c === 1 ? "a" : "or");
                },
                d: function (c) {
                    return "dag" + (c === 1 ? "" : "ar");
                },
                h: function (c) {
                    return "timm" + (c === 1 ? "e" : "ar");
                },
                m: function (c) {
                    return "minut" + (c === 1 ? "" : "er");
                },
                s: function (c) {
                    return "sekund" + (c === 1 ? "" : "er");
                },
                ms: function (c) {
                    return "millisekund" + (c === 1 ? "" : "er");
                },
                decimal: ","
            },
            tr: {
                y: function () {
                    return "yıl";
                },
                mo: function () {
                    return "ay";
                },
                w: function () {
                    return "hafta";
                },
                d: function () {
                    return "gün";
                },
                h: function () {
                    return "saat";
                },
                m: function () {
                    return "dakika";
                },
                s: function () {
                    return "saniye";
                },
                ms: function () {
                    return "milisaniye";
                },
                decimal: ","
            },
            th: {
                y: function () {
                    return "ปี";
                },
                mo: function () {
                    return "เดือน";
                },
                w: function () {
                    return "อาทิตย์";
                },
                d: function () {
                    return "วัน";
                },
                h: function () {
                    return "ชั่วโมง";
                },
                m: function () {
                    return "นาที";
                },
                s: function () {
                    return "วินาที";
                },
                ms: function () {
                    return "มิลลิวินาที";
                },
                decimal: "."
            },
            vi: {
                y: function () {
                    return "năm";
                },
                mo: function () {
                    return "tháng";
                },
                w: function () {
                    return "tuần";
                },
                d: function () {
                    return "ngày";
                },
                h: function () {
                    return "giờ";
                },
                m: function () {
                    return "phút";
                },
                s: function () {
                    return "giây";
                },
                ms: function () {
                    return "mili giây";
                },
                decimal: ","
            },
            zh_CN: {
                y: function () {
                    return "年";
                },
                mo: function () {
                    return "个月";
                },
                w: function () {
                    return "周";
                },
                d: function () {
                    return "天";
                },
                h: function () {
                    return "小时";
                },
                m: function () {
                    return "分钟";
                },
                s: function () {
                    return "秒";
                },
                ms: function () {
                    return "毫秒";
                },
                decimal: "."
            },
            zh_TW: {
                y: function () {
                    return "年";
                },
                mo: function () {
                    return "個月";
                },
                w: function () {
                    return "周";
                },
                d: function () {
                    return "天";
                },
                h: function () {
                    return "小時";
                },
                m: function () {
                    return "分鐘";
                },
                s: function () {
                    return "秒";
                },
                ms: function () {
                    return "毫秒";
                },
                decimal: "."
            }
        };
    }
    HumanizeDurationLanguage.prototype.addLanguage = function (key, lang) {
        this.languages[key] = lang;
    };
    // Internal helper function for Czech language.
    HumanizeDurationLanguage.prototype.getCzechForm = function (c) {
        if (c === 1) {
            return 0;
        }
        else if (Math.floor(c) !== c) {
            return 1;
        }
        else if (c % 10 >= 2 && c % 10 <= 4 && c % 100 < 10) {
            return 2;
        }
        else {
            return 3;
        }
    };
    // Internal helper function for Polish language.
    HumanizeDurationLanguage.prototype.getPolishForm = function (c) {
        if (c === 1) {
            return 0;
        }
        else if (Math.floor(c) !== c) {
            return 1;
        }
        else if (c % 10 >= 2 && c % 10 <= 4 && !(c % 100 > 10 && c % 100 < 20)) {
            return 2;
        }
        else {
            return 3;
        }
    };
    // Internal helper function for Russian and Ukranian languages.
    HumanizeDurationLanguage.prototype.getSlavicForm = function (c) {
        if (Math.floor(c) !== c) {
            return 2;
        }
        else if ((c >= 5 && c <= 20) ||
            (c % 10 >= 5 && c % 10 <= 9) ||
            c % 10 === 0) {
            return 0;
        }
        else if (c % 10 === 1) {
            return 1;
        }
        else if (c > 1) {
            return 2;
        }
        else {
            return 0;
        }
    };
    // Internal helper function for Lithuanian language.
    HumanizeDurationLanguage.prototype.getLithuanianForm = function (c) {
        if (c === 1 || (c % 10 === 1 && c % 100 > 20)) {
            return 0;
        }
        else if (Math.floor(c) !== c ||
            (c % 10 >= 2 && c % 100 > 20) ||
            (c % 10 >= 2 && c % 100 < 10)) {
            return 1;
        }
        else {
            return 2;
        }
    };
    // Internal helper function for Arabic language.
    HumanizeDurationLanguage.prototype.getArabicForm = function (c) {
        if (c <= 2) {
            return 0;
        }
        if (c > 2 && c < 11) {
            return 1;
        }
        return 0;
    };
    // Internal helper function for Slovak language.
    HumanizeDurationLanguage.prototype.getCzechOrSlovakForm = function (c) {
        if (c === 1) {
            return 0;
        }
        else if (Math.floor(c) !== c) {
            return 1;
        }
        else if (c % 10 >= 2 && c % 10 <= 4 && c % 100 < 10) {
            return 2;
        }
        else {
            return 3;
        }
    };
    // Internal helper function for Latvian language.
    HumanizeDurationLanguage.prototype.getLatvianForm = function (c) {
        if (c === 1 || (c % 10 === 1 && c % 100 !== 11)) {
            return 0;
        }
        else {
            return 1;
        }
    };
    return HumanizeDurationLanguage;
}());

var HumanizeDuration = /** @class */ (function () {
    function HumanizeDuration(languageUtil) {
        this.languageUtil = languageUtil;
        this.defaultOptions = {
            language: "en",
            delimiter: ", ",
            spacer: " ",
            conjunction: "",
            serialComma: true,
            units: ["y", "mo", "w", "d", "h", "m", "s"],
            languages: {},
            largest: 10,
            decimal: ".",
            round: false,
            unitMeasures: {
                y: 31557600000,
                mo: 2629800000,
                w: 604800000,
                d: 86400000,
                h: 3600000,
                m: 60000,
                s: 1000,
                ms: 1
            }
        };
        this.options = undefined;
        this.options = this.defaultOptions;
    }
    HumanizeDuration.prototype.humanize = function (value, tempOptions) {
        var options = tempOptions !== undefined
            ? this.extend(this.options, tempOptions)
            : this.defaultOptions;
        return this.doHumanization(value, options);
    };
    HumanizeDuration.prototype.setOptions = function (passedOptions) {
        this.options =
            passedOptions !== undefined
                ? this.extend(this.defaultOptions, passedOptions)
                : this.defaultOptions;
    };
    HumanizeDuration.prototype.getSupportedLanguages = function () {
        var result = [];
        for (var language in this.languageUtil.languages) {
            if (this.languageUtil.languages.hasOwnProperty(language)) {
                result.push(language);
            }
        }
        return result;
    };
    HumanizeDuration.prototype.addLanguage = function (key, lang) {
        this.languageUtil.addLanguage(key, lang);
    };
    HumanizeDuration.prototype.doHumanization = function (ms, options) {
        var i, len, piece;
        // Make sure we have a positive number.
        // Has the nice sideffect of turning Number objects into primitives.
        ms = Math.abs(ms);
        var dictionary = options.languages[options.language] ||
            this.languageUtil.languages[options.language];
        if (!dictionary) {
            throw new Error("No language " + dictionary + ".");
        }
        var pieces = [];
        // Start at the top and keep removing units, bit by bit.
        var unitName;
        var unitMS, unitCount;
        for (i = 0, len = options.units.length; i < len; i++) {
            unitName = options.units[i];
            unitMS = options.unitMeasures[unitName];
            // What's the number of full units we can fit?
            if (i + 1 === len) {
                unitCount = ms / unitMS;
            }
            else {
                unitCount = Math.floor(ms / unitMS);
            }
            // Add the string.
            pieces.push({
                unitCount: unitCount,
                unitName: unitName
            });
            // Remove what we just figured out.
            ms -= unitCount * unitMS;
        }
        var firstOccupiedUnitIndex = 0;
        for (i = 0; i < pieces.length; i++) {
            if (pieces[i].unitCount) {
                firstOccupiedUnitIndex = i;
                break;
            }
        }
        if (options.round) {
            var ratioToLargerUnit = void 0, previousPiece = void 0;
            for (i = pieces.length - 1; i >= 0; i--) {
                piece = pieces[i];
                piece.unitCount = Math.round(piece.unitCount);
                if (i === 0) {
                    break;
                }
                previousPiece = pieces[i - 1];
                ratioToLargerUnit =
                    options.unitMeasures[previousPiece.unitName] /
                        options.unitMeasures[piece.unitName];
                if (piece.unitCount % ratioToLargerUnit === 0 ||
                    (options.largest && options.largest - 1 < i - firstOccupiedUnitIndex)) {
                    previousPiece.unitCount += piece.unitCount / ratioToLargerUnit;
                    piece.unitCount = 0;
                }
            }
        }
        var result = [];
        for (i = 0, pieces.length; i < len; i++) {
            piece = pieces[i];
            if (piece.unitCount) {
                result.push(this.render(piece.unitCount, piece.unitName, dictionary, options));
            }
            if (result.length === options.largest) {
                break;
            }
        }
        if (result.length) {
            if (!options.conjunction || result.length === 1) {
                return result.join(options.delimiter);
            }
            else if (result.length === 2) {
                return result.join(options.conjunction);
            }
            else if (result.length > 2) {
                return (result.slice(0, -1).join(options.delimiter) +
                    (options.serialComma ? "," : "") +
                    options.conjunction +
                    result.slice(-1));
            }
        }
        else {
            return this.render(0, options.units[options.units.length - 1], dictionary, options);
        }
    };
    HumanizeDuration.prototype.render = function (count, type, dictionary, options) {
        var decimal;
        if (options.decimal === void 0) {
            decimal = dictionary.decimal;
        }
        else {
            decimal = options.decimal;
        }
        var countStr = count.toString().replace(".", decimal.toString());
        var word = dictionary[type](count);
        return countStr + options.spacer + word;
    };
    HumanizeDuration.prototype.extend = function (options, override) {
        for (var prop in override) {
            if (options.hasOwnProperty(prop)) {
                options[prop] = override[prop];
            }
        }
        return options;
    };
    return HumanizeDuration;
}());

export { HumanizeDuration, HumanizeDurationLanguage };
